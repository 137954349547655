.w3-round-xxlarge {
    padding: 5px 10px;
    border: 2px solid black;
    border-radius: 10px;
    margin-top: 10px;
    font-weight: 500;
}

.w3-round-xxlarge:hover {
    background-color: #F6F6F6;
}
